<template>
  <div>
    <v-content id="main" tabindex="-1">
      <v-layout :style="{backgroundImage: `url('${page.real_image}')`, backgroundSize: 'cover'}" class="white--text bg-image" py-5 px-0
                tag="section">
        <v-container>
          <v-layout row wrap>
            <v-flex sm6 md4 text-xs-center text-md-left class="secondary">
              <div class="white pa-2">
                <v-img :src="logoPath"/>
              </div>
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex sm6 md4 text-xs-center text-md-left class="secondary">
              <div class="pa-5">
                <dynamic-text :html="page.tekst"/>
              </div>
            </v-flex>
            <v-flex sm5 md7 offset-sm1>
              <div class="white">
                <v-img :src="require('~/assets/img/' + municipality + '_bedankt_uitgebreid.jpg')"/>
              </div>
              <!--
              <v-responsive v-else :aspect-ratio="16/9">
                <iframe :src="youtubeSrc" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen style="width:100%;height: 100%;"/>
              </v-responsive>
              -->
              <!-- Enable button when going live again -->
              <!--
              <div class="mt-5">
                <v-btn :to="{name: municipality + '-bedankt-geschenk'}" color="accent" round large nuxt class="mx-0 mr-4 font-weight-bold">
                  Kies hier jouw geschenk
                  <v-icon small right>far fa-long-arrow-alt-right</v-icon>
                </v-btn>
              </div>
              -->
            </v-flex>
          </v-layout>
        </v-container>
      </v-layout>
    </v-content>
    <faq :questions="questions"/>
  </div>
</template>

<script>
// import {mapState} from 'vuex';
import commonForm from '~/plugins/mixins/form';
import Faq from '~/components/rewards/Faq';

export default {
  components: {
    Faq,
  },
  mixins: [commonForm],
  props: {
    logoPath: {
      type: String,
      default: require('~/assets/img/bedankt.png'),
    },
    municipality: {
      type: String,
      default: 'vlaardingen',
    },
    page: {
      type: Object,
      default: () => {},
    },
    questions: {
      type: Array,
      default: () => ([]),
    },
    youtubeSrc: {
      type: String,
      default: () => 'https://www.youtube.com/embed/PwTE4YAvmT8',
    },
  },
};
</script>
