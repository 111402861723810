<template>
  <v-layout class="greyLight mb-1">
    <v-container>
      <v-layout flex wrap>
        <v-flex md7>
          <h2 class="h3 font-italic">Veelgestelde vragen</h2>
        </v-flex>
      </v-layout>
      <v-expansion-panel class="faq row wrap justify-start">
        <v-expansion-panel-content
          v-for="question in questions"
          :key="question.id"
          class="flex xs12 lg8"
        >
          <!-- TODO: Closed > fa-plus icon | Open > fa-minus icon -->
          <template v-slot:actions>
            <v-icon color="primary">far fa-plus</v-icon>
          </template>
          <template v-slot:header>
            <div class="font-weight-bold primary--text">{{ question.title }}</div>
          </template>
          <v-card color="transparent">
            <v-card-text class="pt-0" v-text="question.answer"/>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-container>
  </v-layout>
</template>

<script>
export default {
  props: {
    questions: {
      type: Array,
      default: () => ([]),
    },
  },
};
</script>

<style lang="stylus" scoped>
.faq
  box-shadow: none !important;

  ::v-deep .v-expansion-panel__container
    background: transparent !important;

  ::v-deep .v-expansion-panel__header
    padding: 12px 16px !important;
</style>
