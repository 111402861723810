<template>
  <bedankt
    :page="page"
    :questions="questions"
    :logo-path="require('~/assets/img/maassluis_bedankt.png')"
    municipality="maassluis"
  />
</template>

<script>
import {mapState} from 'vuex';
import Bedankt from '~/components/rewards/Bedankt';

export default {
  auth: false,
  layout: 'shop',
  components: {
    Bedankt,
  },
  async fetch({store, error}) {
    try {
      await store.dispatch('getPage', 'maassluis-bedankt');
    } catch (e) {
      error({statusCode: 404, message: e.message});
    }
  },
  async asyncData({$axios}) {
    const [questions] = await Promise.all([
      $axios.get(`/vragen`),
    ]);

    return {
      questions: questions.data.data,
    };
  },
  computed: mapState(['page']),
  head() {
    return {
      title: this.page.meta.title,
      meta: [
        this.page.meta.description
          ? {
            hid: 'description',
            name: 'description',
            content: this.page.meta.description,
          }
          : {},
      ],
    };
  },
};
</script>

<style lang="stylus" scoped>
.bg-image
  position relative
  padding 4rem 0
  background-size cover
  z-index 1
</style>
